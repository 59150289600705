export enum FullCodeDescription {
  NAICS = "North American Industry Classification System",
  SIC = "Standard Industry Code"
}

export enum CodeKey {
  NAICS = "naics_code",
  SIC = "sic_code"
}

export enum LexisNexisReturnTypes {
  search = "search",
  report = "report"
}

export enum BankAccountProviders {
  Plaid = 1,
  MoneyThumb = 3
}

export enum OcrolusBookClass {
  COMPLETE = "complete",
  INSTANT = "instant"
}
